<template>
      <div class="navbar navbar-inverse navbar-static-top">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <router-link class="navbar-brand" :to="'/'+$route.params.lang">Quinten HENRY - Freelance Game Programmer</router-link>
        </div>
        <div class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li><router-link :to="'/'+$route.params.lang">Home</router-link></li>
            <li><a href="HENRY_Quinten_Resume.pdf" target="_blank">CV</a></li>
			      <li><router-link :to="'/contact/'+$route.params.lang">Contact</router-link></li>
            <li>
              <div class="languagedropdown">
                <a v-if="$route.params.lang=='nl'">Dutch
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="padding-top: 5px;" fill="white" class="bi bi-caret-down" viewBox="0 0 16 16">
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                  </svg>
                </a>
                <a v-else>English
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="padding-top: 5px;" fill="white" class="bi bi-caret-down" viewBox="0 0 16 16">
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                  </svg>
                </a>
                <div class="dropdown-content">
                  <router-link v-if="$route.params.lang=='nl'" to="en">English</router-link>
                  <router-link v-else to="nl">Dutch</router-link>
                </div>
              </div>
            </li>
          </ul>
        </div><!--/.nav-collapse -->
      </div>
    </div>
  <router-view v-bind="{tagList:tagList,blogEntries:blogEntries}"/>
  <div id="footer">
		<div class="container">
				<div style="text-align:center">
					<a href="https://www.linkedin.com/in/quinten-henry-aba467136/" target="_blank"><img class="ico" src="~@/img/icons/LinkedInIcon.png" width="50" height="50" alt="LinkedIn"></a>
					<a href="mailto:quintenhenry@hotmail.be"><img class="ico" src="~@/img/icons/MailIcon.png" width="50" height="50" alt="Mail"></a>
				</div><!-- /col-lg-4 -->
		</div>
	</div>
</template>

<style lang="sass">
@import "./styles/bootstrap.css"
@import "./styles/main.css"
</style>

<script>
const axios = require("axios").default;
import { withModifiers } from "@vue/runtime-dom";
import BlogEntry from "./classes/BlogEntry";

function sortSet(set) {
  const entries = [];
  for (const member of set) {
    entries.push(member);
  }
  set.clear();
  set.add("All");
  for (const entry of entries.sort()) {
    set.add(entry);
  }
  return set;
}



export default {
  data() {
    return {
      blogEntries: [],
      tagList: []
    };
  },
  mounted: function () {
    let TagEntriesSet=new Set();
    let blogEntriesList=[];
    this.RequestBlogEntry(1,TagEntriesSet,blogEntriesList);
  },
  methods: {
    RequestBlogEntry: function (index,TagEntriesSet,blogEntriesList) {
      axios
        .get("../entries/Blog" + index + ".xml")
        .then((resp) => {
          if (resp.status == 200) {
            let entry = new BlogEntry(resp.data);

            if(!entry.hidden){
              for (var tagID in entry.tags) TagEntriesSet.add(entry.tags[tagID]);
            }
            blogEntriesList.push(entry);

            this.RequestBlogEntry(index + 1,TagEntriesSet,blogEntriesList);
          }
        })
        .catch((resp) => {
          //Reached end
          sortSet(TagEntriesSet);

          this.blogEntries=blogEntriesList;
          this.tagList=Array.from(TagEntriesSet);
        });
    }
  },
};
</script>