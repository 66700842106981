<template>
  <!-- +++++ Welcome Section +++++ -->
  <div id="ww">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2 centered">
          <h1>Quinten Henry - Freelance Game Programmer</h1>

          <template v-if="$route.params.lang=='nl'">
            <p>
              Ik ben een freelance game en simulatie programmeur die afgestudeerd is als bachelor game development op 
              <a href="http://www.digitalartsandentertainment.be/" target="_blank">Howest DAE</a>.
              Ik heb veel ervaring met zowel Unity en Unreal Engine en heb mezelf volledig verdiept in het ontwikkelings process van AAA games.
              Graphics programming and gameplay programming zijn mijn specialiteiten.
            </p>
            <p>
              Ik woon momenteel in België en focus de laatste jaren op mijn werk als freelancer aan games en simulaties voor mijn klanten.
            </p>
            <p>Op zoek naar een programmeur die je ideeën ontwikkeld?</p>
          </template>
          <template v-else>
              <p>I am a freelance game and simulation programmer who graduated as
              bachelor game development at
              <a href="http://www.digitalartsandentertainment.be/" target="_blank">Howest DAE</a>.
              I have a lot of experience with both Unity and Unreal Engine and
              immersed myself in the full production process of AAA games.
              Graphics programming and gameplay programming are my speciality.
            </p>
            <p>
              I am currently living in Belgium and over the past years I've been focussing on working as a freelance
              programmer on games and simulations for my clients.
            </p>
            <p>Looking for a programmer to develop your ideas?</p>
          </template>

          <router-link :to="'/Contact/'+$route.params.lang">
            <button>Get in touch!</button>
          </router-link>
        </div>
        <!-- /col-lg-8 -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </div>
  <!-- /ww -->

  <!-- +++++ Projects Section +++++ -->
  <div class="tagcontainer container">
    <template v-for="tag in tagList" :key="tag">
      <div v-if="selectedTag==tag" class="active">{{ tag }}</div>
      <div v-else @click="ClickTag(tag)">{{ tag }}</div>
    </template>
  </div>
  <div class="container pt">
    <div class="row mt centered portfoliogrid">
      <template v-for="(entry,index) in blogEntries" :key="entry">
      <div class="col-lg-4" v-if="entry.sharesTag(selectedTag) && !entry.hidden">
          <router-link class="zoom green" :to="{ path: '/Post/'+$route.params.lang, query: { id: index }}">
            <img class="img-responsive" :src="entry.coverImage" alt="" />
             <div class="tagcontaineroverlay">
               <template v-for="(tag) in entry.tags" :key="tag">
                <div>{{tag}}</div>
               </template>
            </div>
            <diV class="overlay"></div>
          </router-link>
          <p>{{ entry.title }}</p>
      </div>
      </template>
    </div>
  </div>
  <!-- /container -->
</template>
<script>

export default{
  props: {
    tagList:{
      type: Array,
      required:true
    },
    blogEntries:{
      type: Array,
      required:true
    }
  },
  data(){
    return {selectedTag: ""}
  },
  mounted:function(){
    if(this.tagList.length>0)
      this.selectedTag=this.tagList[0];
  },
  methods:{  ClickTag: function(tag){
      console.log(tag);
      this.selectedTag=tag;
    }
  },
  watch:{
    tagList: function(){
      this.selectedTag=this.tagList[0];
    }
  }
}
</script>