export default class BlogEntry {

    constructor(xmltext)
    {

        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(xmltext,"text/xml");

        this.title=xmlDoc.getElementsByTagName("Title")[0].childNodes[0].nodeValue;
        this.coverImage=xmlDoc.getElementsByTagName("CoverImage")[0].childNodes[0].nodeValue;

        this.hidden=false;
        if(xmlDoc.getElementsByTagName("Hidden").length>0)
            this.hidden=true;

        var tagNodes=xmlDoc.getElementsByTagName("Tag");

        this.tags=[];
        for(var index=0;index<tagNodes.length;index++)
        {
            this.tags.push(tagNodes[index].textContent);
        }
        this.tags=this.tags.sort();
        var paragraphNodes=xmlDoc.getElementsByTagName("p");

        this.paragraphs=[];
        this.paragraphsnl=[];

        for(var index=0;index<paragraphNodes.length;index++)
        {
            let inlineAttribute=paragraphNodes[index].getAttribute('inline');
            
            let isinlineparagraph=false;
            if(inlineAttribute!==null)
                isinlineparagraph=inlineAttribute;

            let hrefAttribute=paragraphNodes[index].getAttribute('href');
            
            let hrefurl="";
            if(hrefAttribute!==null)
                hrefurl=hrefAttribute;

            let nlAttribute=paragraphNodes[index].getAttribute('nl');

            let en=true;
            if(nlAttribute!==null)
                en=false;

            if(en)
                this.paragraphs.push({isInline:isinlineparagraph,href:hrefurl,text:paragraphNodes[index].textContent});
            else
                this.paragraphsnl.push({isInline:isinlineparagraph,href:hrefurl,text:paragraphNodes[index].textContent});
        }

        var videoNodes=xmlDoc.getElementsByTagName("Video");

        this.videos=[];
        for(var index=0;index<videoNodes.length;index++)
        {
            this.videos.push(videoNodes[index].textContent);
        }

        var imageNodes=xmlDoc.getElementsByTagName("Image");

        this.images=[];
        for(var index=0;index<imageNodes.length;index++)
        {
            this.images.push(imageNodes[index].textContent);
        }

        var skillNodes=xmlDoc.getElementsByTagName("Skill");

        this.skills=[];
        for(var index=0;index<skillNodes.length;index++)
        {
            this.skills.push(skillNodes[index].textContent);
        }
        
        var contributionNodes=xmlDoc.getElementsByTagName("Contribution");

        this.hasContributions=false;
        this.contributions=[];
        for(var index=0;index<contributionNodes.length;index++)
        {
            this.contributions.push(contributionNodes[index].textContent);
            this.hasContributions=true;
        }
    }

    sharesTag(selectedTag){

            if(selectedTag==="All")
                return true;

            for(let j = 0; j < this.tags.length; j++) {
                if(selectedTag ===  this.tags[j]) {
                    return true;
                }
            }
        
        return false;
    }
}