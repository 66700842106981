import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../pages/Index.vue'
import Contact from '../pages/Contact.vue'
import Post from '../pages/Post.vue'

const routes = [
  { 
    path: '/:lang',
    name: 'Home Lang',
    component: Index,
    meta:{
      name:'Quinten Henry - Portfolio'
    }
  },
  { 
    path: '/',
    name: 'Home',
    redirect: '/en',
    meta:{
      name:'Quinten Henry - Portfolio'
    }
  },
  {
    path: '/Contact/:lang',
    name: 'Contact',
    component: Contact,
    meta:{
      name:'Quinten Henry - Contact'
    }
  },
  {
    path: '/Post/:lang',
    name: 'Post',
    component: Post,
    meta:{
      name:'Quinten Henry - Portfolio'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.name;
  next();
});

export default router
