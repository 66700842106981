<template>
<div class="container pt postcontainer">
	<div class="row mt">
		<div class="col-lg-6 col-lg-offset-3 centered">
				<h3>{{blogEntry.title}}</h3>
				<hr>
        <template v-if="$route.params.lang=='nl'">
          <template v-for="paragraph in blogEntry.paragraphsnl" :key="paragraph">
            <a v-if="paragraph.href!==''" :href="paragraph.href" target="_blank">{{paragraph.text}}</a>
            <p v-else-if="paragraph.isInline" style="display:inline">{{paragraph.text}}</p>
            <p v-else>{{paragraph.text}}</p>
          </template>
        </template>
        <template v-else>
          <template v-for="paragraph in blogEntry.paragraphs" :key="paragraph">
            <a v-if="paragraph.href!==''" :href="paragraph.href" target="_blank">{{paragraph.text}}</a>
            <p v-else-if="paragraph.isInline" style="display:inline">{{paragraph.text}}</p>
            <p v-else>{{paragraph.text}}</p>
          </template>
        </template>


        <h1 v-if="$route.params.lang=='nl'">Vaardigheden en technologieën</h1>
				<h1 v-else>Skills and technologies used:</h1>
        <ul>
          <template v-for="skill in blogEntry.skills" :key="skill">
            <li>{{skill}}</li>
          </template>
        </ul>
        <template v-if="blogEntry.hasContributions===true">
          <h1 v-if="$route.params.lang=='nl'">
            Mijn bijdragen:
          </h1>
          <h1 v-else>
            My contributions:
          </h1>
        </template>
        <ul>
          <template v-for="contribution in blogEntry.contributions" :key="contribution">
            <li>{{contribution}}</li>
          </template>
        </ul>
        
		</div>
	</div>
		<div class="row mt centered">	
			<div class="col-lg-8 col-lg-offset-2">
        <template v-for="video in blogEntry.videos" :key="video">
				  <iframe width="750" height="420" :src="video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</template>

        <template v-for="image in blogEntry.images" :key="image">
          <p><img class="img-responsive" :src="image" alt=""></p>
        </template>
		    </div>
	    </div><!-- /row -->
</div><!-- /container -->
<!-- +++++ Call To Action Section +++++ -->
  <div id="ww">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2 centered">
          <h1>Quinten Henry - Freelance Game Programmer</h1>
          <p v-if="$route.params.lang=='nl'">
            Op zoek naar een programmeur die je ideeën ontwikkeld?
          </p>
          <p v-else>
            Looking for an experienced programmer to develop you ideas?
          </p>
          <router-link :to="'/Contact/'+$route.params.lang"> 
            <button>Get in touch!</button>
          </router-link>
        </div>
        <!-- /col-lg-8 -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </div>
  <!-- /ww -->


</template>
<script>
export default {
  data() {
    return {
      blogEntry: {}
    };
  },
  props: {
    blogEntries:{
      type: Array,
      required:true
    }
  },
  mounted:function(){
    if(this.blogEntries.length>0)
    {
      const id = this.$route.query.id;
      this.blogEntry = this.blogEntries[id];
      document.title="Quinten Henry - " +this.blogEntry.title;
    }
      window.scrollTo(0, 0);
  },
  watch:{
    blogEntries:
      function(){
        const id = this.$route.query.id;
        this.blogEntry = this.blogEntries[id];
        document.title="Quinten Henry - " +this.blogEntry.title;
      }
  }
};
</script>
