<template>
    <div id="ww">
		<div class="formcontainer small">
	    <div class="container">
			<div class="row">
				<div class="col-lg-8 col-lg-offset-2 centered">
					<h1>Contact Info</h1>
					<p>quintenhenry@hotmail.be</p>
					<p>+3491297415</p>
					<a href="https://www.linkedin.com/in/quinten-henry-aba467136/" target="_blank">LinkedIn</a>
					<p></p>
					<a href="https://github.com/QuintenHenry" target="_blank"><img class="ico">GitHub</a>
					<p></p>
					<p>Ghent, Belgium</p>
				</div><!-- /col-lg-8 -->
			</div><!-- /row -->
	    </div> <!-- /container -->
				<div class="container">
  		<h1>Send a message</h1>
  		<form @submit="SendForm">
    		<div class="form-group">
      			<div class="form-row">
        			<div class="col">
          			<input v-model="fullName" type="text" name="name" class="form-control" placeholder="Full Name" required> 
        		</div>
        	<div class="col">
				<br>
        <input v-model="email" type="email" name="email" class="form-control" placeholder="Email Address" required>
        </div>
      </div>
    </div>
    <div class="form-group">
      <textarea v-model="message" placeholder="Your Message" class="form-control" name="message" rows="10" required></textarea>
    </div>
    <button type="submit" class="SendButton">Send</button>
	<p v-if="success===true" id="SuccessButton">Your message has been received!</p>
  </form>
</div>
		</div>
	</div><!-- /ww -->
</template>
<script>
const axios = require("axios").default;

export default {
	data(){
		return {
			fullName:"",
			email:"",
			message:"",
			success:false
		}
	},
    methods:{
        SendForm: function(e) { 	
			e.preventDefault();

			console.log("Send called");
			axios.defaults.headers.post['Content-Type'] = 'application/json';
			axios.post('https://formsubmit.co/ajax/quintenhenry@hotmail.be', {

    		name: this.fullName,
    		message: this.message,
			email:this.email
		})
    .then(()=>{
		this.message="";
		this.fullName="";
		this.email="";
		this.success=true;
		})
    .catch(error => console.log(error));
	}
    }
}
</script>
